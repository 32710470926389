import { HTMLAttributes, FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { CommonPanel } from '../CommonPanel';
import { GameTypeNumbers } from '../GameType';
import { LETTER_COLORS as LETTER_COLORS_75 } from '../Ball75';
import { LETTER_COLORS as LETTER_COLORS_80 } from '../Ball80';

type NextCallsProps =
    | 'blue'
    | 'red'
    | 'purple'
    | 'green'
    | 'yellow'
    | 'transparent';

export type CallsCardProps = HTMLAttributes<HTMLDivElement> & {
    gameType: GameTypeNumbers;
    calls: number;
    nextCalls?: string[];
};

export const CallsContainer = styled(CommonPanel)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: Signika-Bold;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    width: 7.25rem;
    height: auto;
`;

export const CallsText = styled.div<{ size: boolean }>`
    ${({ theme, size }) => `
        font-size: ${size}rem;
        line-height: ${size}rem;
        color: ${theme.callsCard.color.text};
    `};
`;

export const CallsNumber = styled.div`
    font-size: 3.125rem;
    line-height: 3.125rem;
    color: ${(props) => props.theme.callsCard.color.number};
`;

export const BallColorContainer = styled.div`
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-top: 0.625rem;
`;
export const BallColor = styled.div<{
    color: NextCallsProps;
}>`
    width: 1.375rem;
    height: 1.375rem;
    border-radius: 50%;

    ${({ color, theme }) => `
        border: 0.125rem solid ${theme.callsCard.ballColorBorder};
        background-color: ${theme.callsCard.nextCalls[color]};
    `};
`;

export const LETTER_COLORS = {
    75: {
        ...LETTER_COLORS_75,
    },
    80: {
        ...LETTER_COLORS_80,
    },
};

const EMPTY_BALL_COLOR = [...Array(3)];

export const CallsCard: FC<CallsCardProps> = ({
    calls,
    nextCalls,
    gameType,
    ...props
}) => {
    const { t } = useTranslation();

    return (
        <CallsContainer {...props} type="secondary">
            <CallsText size={t('callsCard.calls.size')}>
                {t('callsCard.calls.msg')}
            </CallsText>
            <CallsNumber>{calls}</CallsNumber>
            {nextCalls && gameType !== 90 ? (
                <BallColorContainer>
                    {nextCalls.length > 0
                        ? nextCalls.map((letters, index) => {
                              let color;

                              switch (gameType) {
                                  case 75:
                                      color =
                                          LETTER_COLORS[75][
                                              letters.slice(
                                                  0,
                                                  1
                                              ) as keyof (typeof LETTER_COLORS)[75]
                                          ];

                                      break;
                                  case 80:
                                      color =
                                          LETTER_COLORS[80][
                                              letters.slice(
                                                  1,
                                                  2
                                              ) as keyof (typeof LETTER_COLORS)[80]
                                          ];
                                      break;
                                  default:
                                      throw new Error('No gameType provided.');
                              }

                              return (
                                  <BallColor
                                      key={index}
                                      color={color as NextCallsProps}
                                  />
                              );
                          })
                        : EMPTY_BALL_COLOR.map((_v, index) => {
                              return (
                                  <BallColor
                                      key={index}
                                      color={'transparent'}
                                  />
                              );
                          })}
                </BallColorContainer>
            ) : null}
        </CallsContainer>
    );
};
