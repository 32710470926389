import { FC } from 'react';
import { isMobile } from 'react-device-detect';
import styled, { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

import {
    CommonModal,
    CommonModalProps,
    CommonModalContainer,
} from '../CommonModal';

export const GameCancelledModalContainer = styled(CommonModalContainer)`
    display: flex;
    flex-direction: column;
    align-items: center;

    ${!isMobile &&
    `left: 16rem;
    margin-top: 6rem;`}
`;

export const GameCancelledImage = styled.img`
    width: 10.313rem;
    height: 10.313rem;
    margin-bottom: 2.438rem;
`;

export const GameCancelledText = styled.div`
    font-family: Signika-Bold;
    font-size: 2.875rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
    text-align: center;
    color: ${({
        theme: {
            commonModal: { color },
        },
    }) => color.content};
`;

export const GameCancelledModal: FC<CommonModalProps> = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();

    return (
        <CommonModal {...props} Container={GameCancelledModalContainer}>
            <GameCancelledImage
                src={theme.images.gameCancelled}
                alt="Game Cancelled Image"
            />
            <GameCancelledText>
                {t('information.gameCancelled')}.
            </GameCancelledText>
        </CommonModal>
    );
};
