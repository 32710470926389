import * as Sentry from '@sentry/react';
import { FC, useEffect, useState, memo } from 'react';
import {
    createBoundedTiles,
    createMatrixCombinations,
} from '@parlaygames/bingo-utility';
import styled, { css } from 'styled-components';
import { GameTypeProps } from '../GameType';

export const MATRICES = {
    75: createBoundedTiles(5, 5, true),
    80: createBoundedTiles(4, 4, true),
    90: createBoundedTiles(9, 3, true),
    pattern: [],
};

export const GAME_TYPE = {
    75: css`
        width: 3.625rem;
        height: 3.625rem;
    `,
    80: css`
        width: 2.875rem;
        height: 2.875rem;
    `,
    90: css`
        width: 6.5rem;
        height: 2.125rem;
    `,
};

export type BingoCardPatternPart = {
    patterns: number[][];
    matches: number;
};

export type BingoCardPatternProps = GameTypeProps & {
    patternType: keyof typeof MATRICES;
    parts: BingoCardPatternPart[];
    currentPart?: number;
    delay?: number;
};

export const BingoCardPatternContainer = styled.div`
    display: inline-block;
`;

export const BingoCardPatternRow = styled.div`
    display: flex;
`;

export const BingoCardPatternCell = styled.div<{ filled: boolean }>`
    width: 0.597rem;
    height: 0.597rem;
    margin-right: 0.125rem;
    margin-bottom: 0.125rem;
    border-radius: 0.094rem;

    ${({ filled, theme }) => `
        background-color: ${
            filled
                ? theme.bingoCardPatter.filled
                : theme.bingoCardPatter.notFilled
        };
    `};
`;

const BingoCardPlaceHolder = styled.div<GameTypeProps>`
    ${({ gameType }) => GAME_TYPE[gameType]}

    opacity: 0;
`;

export const BingoCardPattern: FC<BingoCardPatternProps> = memo(
    ({
        patternType,
        parts,
        gameType,
        currentPart = 0,
        delay = 1000,
        ...props
    }) => {
        const [matrix, setMatrix] = useState<number[][] | null>(null);

        useEffect(() => {
            try {
                if (parts.length === 0) {
                    return;
                }

                const matrix = MATRICES[patternType];
                const matches = parts[currentPart]?.matches || 0;
                const patterns = parts[currentPart]?.patterns || [];
                const mappedPatterns = patterns.map((v) => v || []);

                let matrices = createMatrixCombinations(
                    matrix,
                    matches,
                    mappedPatterns
                );

                const id = setInterval(() => {
                    const { value, done } = matrices.next();

                    if (done) {
                        matrices = createMatrixCombinations(
                            matrix,
                            matches,
                            mappedPatterns
                        );
                    } else {
                        setMatrix(value as number[][]);
                    }
                }, delay);

                return () => {
                    clearInterval(id);
                };
            } catch (error) {
                if (error instanceof Error) {
                    Sentry.captureException(error.message);
                }
            }
        }, [patternType, parts, delay, currentPart]);

        // We don't support pattern
        if (
            parts.length === 0 ||
            matrix === null ||
            patternType === 'pattern'
        ) {
            return <BingoCardPlaceHolder gameType={gameType} />;
        }

        return (
            <BingoCardPatternContainer {...props}>
                {matrix.map((row, rowIndex) => (
                    <BingoCardPatternRow key={rowIndex}>
                        {row.map((filled, filledIndex) => (
                            <BingoCardPatternCell
                                key={filledIndex}
                                filled={filled === 1}
                            />
                        ))}
                    </BingoCardPatternRow>
                ))}
            </BingoCardPatternContainer>
        );
    },
    (prev, next) => {
        return (
            prev.parts.length === next.parts.length &&
            prev.patternType === next.patternType &&
            prev.currentPart === next.currentPart
        );
    }
);
